/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-hail': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.405 4.527a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10.5H13a3 3 0 00.405-5.973M8.5 1.5a4 4 0 013.976 3.555.5.5 0 00.5.445H13a2 2 0 01-.001 4H3.5a2.5 2.5 0 11.605-4.926.5.5 0 00.596-.329A4 4 0 018.5 1.5M3.75 15.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0m.408-3.724a.5.5 0 01.316.632l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.316M7.75 15.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0m.408-3.724a.5.5 0 01.316.632l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.316m3.592 3.724a.75.75 0 11-1.5 0 .75.75 0 011.5 0m.408-3.724a.5.5 0 01.316.632l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.316"/>',
    },
});
